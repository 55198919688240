import React, { lazy, Suspense } from 'react';
import ProgressControl from '../../components/ProgressControl';

const LazyMoleculeEditor = lazy(() => import('./LazyMoleculeEditor'));

function MoleculeEditor(props: any) {
  return (
    <div>
      <Suspense fallback={<div></div>}>
        <LazyMoleculeEditor {...props} />
      </Suspense>
    </div>);
}

export default MoleculeEditor;
