import { io, Socket } from "socket.io-client";
import { getSession } from "../../services/Login";

class WebSocketConnection {
  private static instance: WebSocketConnection;
  private socket: Socket;

  constructor() {
    const url = "https://dev.odanchem.org/";
    const path = "/api/v2/socket.io";
    const session = getSession();
  
    this.socket = io(url, {
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
      transports: ["websocket", "polling", "flashsocket"],
      auth: {'jwt': session?.access_token},
      path,
    
    });
    if (this.socket.connected) {
      this.socket.on("connection", console.log);
      this.socket.on("disconnect", console.log);
      this.socket.on("connect", console.log);
      this.socket.on("reconnect_attempt", console.log);
      this.socket.on("reconnect", console.log);
      this.socket.on("connect_error", (err) => {
        console.error(err.message);
      });
      this.initializeListeners();
    }
    else {
      console.error("Socket is not connected");
    }
  }

  public static getInstance(): WebSocketConnection {
    if (!WebSocketConnection.instance) {
      WebSocketConnection.instance = new WebSocketConnection();
    }
    return WebSocketConnection.instance;
  }
  
  public getSocket(): Socket {
    return this.socket;
  }

  private initializeListeners(): void {
    this.socket.on("connect", () => {
      console.log("connection established");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    //this.socket.on("/search/finish", (data) => console.log("/search/finish", data));
    //this.socket.on("/search/progress", (data) => console.log("/search/progress", data));
  }

  public startSearch(type: string, params: any): void {
    const result = this.socket.emit("/search/start", { type, params });
    console.log(result);
  }

  public clearSearches(): void {
    console.log(this.socket.emit("/debug/clear"));
  }
}

export default WebSocketConnection.getInstance();